<template>
  <div>
    <dx-modal title="Configurações do plano"
              id="insurance-setting"
              size="xl"
              v-if="show"
              :value="show"
              @input="close"
    >
      <div class="loading loading-lg" v-if="loading" />
      <template v-else>
        <div class="columns">
          <div class="column col-3 form-group" v-if="form.id">
            <label class="form-switch">
              <input type="checkbox" v-model="form.active">
              <i class="form-icon"></i>Registro ativo
            </label>
          </div>
          <div class="column col-12 form-group"
               :class="{'has-error': $v.form.name.$error}">
            <label class="form-label" for="name">Nome do plano</label>
            <input type="text"
                   id="name"
                   class="form-input"
                   v-model="form.name"
            >
          </div>
          <div class="column col-4 col-md-12 col-sm-12 form-group">
            <label for="cnpj" class="form-label">CNPJ</label>
            <input type="text" class="form-input"
                   v-model="form.identity.value"
                   placeholder="00.000.000/0000-00"
                   v-mask="'00.000.000/0000-00'">
          </div>
          <div class="column col-4 col-md-12 col-sm-12 form-group">
            <label for="plan-record" class="form-label">Registro na ANS</label>
            <input type="text" class="form-input" v-model="form.record"
                   placeholder="00000" autocomplete="nope" v-mask-number>
          </div>
          <div class="column col-4 col-md-12 col-sm-12 form-group">
            <label for="plan-type" class="form-label">Tipo</label>
            <select class="form-select" v-model="form.type">
              <option :value="null">[Selecione]</option>
              <option v-for="(text, value) in insuranceTypes" :value="value" :key="value">
                {{ text }}
              </option>
            </select>
          </div>
        </div>
        <st-tabs id="plan-tabs" ref="plan-tabs"
                 @changed="changeTabs" v-if="form.id">
          <st-tab id="tab-setting" name="Regras gerais">
            <plan-rules
              v-if="selectedTab !== null"
              ref="general"
              :data="form.settings.general"
            />
          </st-tab>
          <st-tab id="tab-tiss" name="TISS">
            <plan-tiss
              v-if="selectedTab !== null"
              ref="tiss"
              :data="form.settings.tiss"
            />
          </st-tab>
          <st-tab id="tab-professionals" name="Profissionais">
            <plan-professionals
              v-if="form.id && selectedTab !== null"
              :origin="getOrigin"
            />
            <div class="empty mt-2" v-else>
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Profissionais</p>
              <p class="empty-subtitle">
                Você precisa primeiro salvar o plano para depois vincular os profissionais
              </p>
            </div>
          </st-tab>
          <st-tab id="tab-procedures" name="Procedimentos">
            <plan-procedures
              v-if="form.id && selectedTab !== null"
              :origin="getOrigin"
            />
            <div class="empty mt-2" v-else>
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Procedimentos</p>
              <p class="empty-subtitle">
                Você precisa primeiro salvar o plano para depois vincular os procedimentos
              </p>
            </div>
          </st-tab>
        </st-tabs>
        <div class="empty mt-2" v-else>
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Configurações</p>
          <p class="empty-subtitle">
            Primeiro salve este novo plano para ter acesso as configurações.
          </p>
        </div>
      </template>
      <template slot="footer">
        <div class="divider" />
        <button class="btn btn-gray btn-icon btn-icon-left float-left"
                @click="openScheduleConfig" v-if="form.id">
          <fa-icon :icon="['fal', 'calendar-day']" />Gerenciar plano nas agendas
        </button>
        <button class="btn btn-primary btn-icon btn-icon-left mr-1"
                :class="{loading: saving}"
                :disabled="saving"
                @click="save"
                v-if="!hideSave">
          <fa-icon :icon="['fal', 'save']" /> Salvar
        </button>
        <button class="btn" @click="close">Sair</button>
      </template>
    </dx-modal>
    <modal-schedule-config
      v-if="scheduleConfigModal.show"
      :show="scheduleConfigModal.show"
      type="insurance"
      :id="form.id"
      :name="form.name"
      :insurance-id="form.insurance.id"
      @close="scheduleConfigModal.show = false"
    />
  </div>
</template>

<script>
import { types as insuranceTypes } from 'src/data/insurance-types';
import { required } from 'vuelidate/src/validators';
import formMixin from 'src/mixins/form';
import ModalScheduleConfig from 'src/app/schedule/components/modals/Config.vue';
import { mergeFrom } from '@/helpers/object';
import PlanRules from './PlanRules.vue';
import PlanTiss from './PlanTiss.vue';
import PlanProfessionals from './PlanProfessionals.vue';
import PlanProcedures from './PlanProcedures.vue';

export default {
  mixins: [formMixin],
  components: {
    ModalScheduleConfig,
    PlanRules,
    PlanTiss,
    PlanProfessionals,
    PlanProcedures,
  },
  props: {
    show: {
      type: Boolean,
    },
    insurance: {
      type: Object,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      hideSave: false,
      selectedTab: null,
      form: this.blankForm(),
      scheduleConfigModal: {
        show: false,
      },
      insuranceTypes,
    };
  },
  computed: {
    getOrigin() {
      return {
        type: 'plan',
        insurance: {
          id: this.form.insurance.id,
          type: this.form.insurance.type,
          name: this.form.insurance.name,
          plan: {
            id: this.form.id,
            name: this.form.name,
            type: this.form.type,
            record: this.form.record,
            identity: this.form.identity,
          },
        },
      };
    },
  },
  mounted() {
    this.form.insurance.id = this.insurance.id;
    this.form.insurance.type = this.insurance.type;
    this.form.insurance.name = this.insurance.name;
    if (this.id) {
      this.load();
    }
  },
  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },
  methods: {
    load() {
      this.loading = true;

      return this.$http
        .get(`/insurances/${this.insurance.id}/plans/${this.id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit('close');
    },
    save() {
      if (this.saving) {
        return;
      }

      this.$v.form.$touch();

      if (this.form.id) {
        const generalError = this.$refs.general.validity();
        const tissError = this.$refs.tiss.validity();
        if (generalError) {
          this.$refs['plan-tabs'].selectTab('tab-setting');
        } else if (tissError) {
          this.$refs['plan-tabs'].selectTab('tab-tiss');
        }
      }

      if (this.$v.form.$error) {
        this.$toast
          .show('Verifique os campos obrigatórios!',
            { type: 'error', timeout: 5000 });
        return;
      }

      this.saving = true;

      const data = this.formatData(this.form);

      const request = !data.id
        ? this.$http.post(`/insurances/${this.insurance.id}/plans`, data)
        : this.$http.put(`/insurances/${this.insurance.id}/plans/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          this.form.id = result.id;
          this.$toast.show('Registro salvo!');
        })
        .catch(() => {})
        .finally(() => {
          this.saving = false;
        });
    },
    formatData(form) {
      const data = this.clone(form);

      if (!data.settings.general.record.digits.enabled) {
        data.settings.general.record.digits.min = '';
        data.settings.general.record.digits.max = '';
      }

      if (!data.settings.general.billing.accounts.enabled) {
        data.settings.general.billing.accounts.quantity = '';
      }

      if (!data.settings.general.billing.return.enabled) {
        data.settings.general.billing.return.days = '';
      }

      if (data.settings.general.providerReferral.mode !== 'automatic') {
        data.settings.general.providerReferral.lastValue = '';
      }

      if (!data.settings.tiss.sadt.authorization.fillValidityDate.enabled) {
        data.settings.tiss.sadt.authorization.fillValidityDate.days = '';
      }

      if (data.settings.tiss.contract.type !== 'company') {
        data.settings.tiss.contract.code = '';
        data.settings.tiss.contract.identity = '';
        data.settings.tiss.contract.name = '';
        data.settings.tiss.contract.cnes = '';
      }

      if (!data.settings.general.table.procedureId) {
        delete data.settings.general.table.procedureId;
      }

      if (!data.settings.general.table.examId) {
        delete data.settings.general.table.examId;
      }

      if (!data.settings.general.table.materialId) {
        delete data.settings.general.table.materialId;
      }

      if (!data.settings.general.table.medicineId) {
        delete data.settings.general.table.medicineId;
      }

      return data;
    },
    changeTabs({ tab }) {
      this.hideSave = tab.hash === 'tab-professionals' || tab.hash === 'tab-procedures';
      this.selectedTab = tab.hash;
    },
    openScheduleConfig() {
      this.scheduleConfigModal.show = true;
    },
    blankForm() {
      return {
        id: '',
        name: '',
        type: null,
        record: '',
        identity: {
          type: 'cnpj',
          value: '',
        },
        active: true,
        insurance: {
          id: '',
          type: '',
          name: '',
        },
        settings: {
          general: this.blankGeneralForm(),
          tiss: this.blankTissForm(),
        },
      };
    },
    blankGeneralForm() {
      return {
        aso: {
          requiredClient: false,
        },
        record: {
          required: false,
          requiredValidity: false,
          fillValidityWithServiceDate: false,
          digits: {
            enabled: false,
            min: '',
            max: '',
          },
        },
        billing: {
          blockValues: false,
          hideInsuranceValue: false,
          allowDifferentReferralTypes: false,
          billingForIndividualSessions: false,
          accounts: {
            enabled: false,
            quantity: '',
          },
          return: {
            enabled: false,
            days: '',
          },
          transitions: [],
        },
        financial: {
          methodId: '',
          categoryId: '',
          bankAccountId: '',
        },
        taxes: {
          iss: '',
          irpj: '',
          pis: '',
          csll: '',
          cofins: '',
          epp: '',
          admin: '',
          others: '',
        },
        providerReferral: {
          lastValue: '',
          mode: 'automatic',
        },
        table: {
          procedureId: '',
          examId: '',
          materialId: '',
          medicineId: '',
        },
      };
    },
    blankTissForm() {
      return {
        enabled: false,
        version: '',
        xml: {
          batch: '',
          fields: {
            insuranceReferral: false,
          },
        },
        contract: {
          type: 'company',
          code: '',
          identity: '',
          name: '',
          cnes: '',
        },
        referral: {
          origin: {
            type: 'company',
            value: 'identity',
          },
          requester: {
            type: 'company',
            value: 'identity',
          },
          executant: {
            type: 'company',
            value: 'identity',
          },
          professional: {
            type: 'company',
            value: 'identity',
          },
        },
        consultation: {
          sadt: false,
          requiredInsuranceReferral: false,
        },
        sadt: {
          fillRequestDataWithServiceDate: false,
          fillAuthorizationDateWithServiceDate: false,
          requiredRequestDate: false,
          authorization: {
            fillValidityDate: {
              enabled: false,
              days: '',
            },
            fillInsuranceReferralWithPassword: false,
            requiredMainReferral: false,
            requiredInsuranceReferral: false,
            requiredDate: false,
            requiredPassword: false,
            requiredValidity: false,
          },
        },
      };
    },
  },
};
</script>

<style lang="scss">
#insurance-setting {
  //
}
</style>
